/* eslint-disable */
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardNumberElement } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import { useModal } from 'react-hooks-use-modal';
import { useAuth0 } from '@auth0/auth0-react';
import { subscriptionService } from '../../services';
import { loadingAction, subscriptionAction, userAction } from '../../store/actions';
import './Stripe-form.css';
import ApplePay from '../apple-pay/ApplePay';
import { UniversalModal } from '../universal-modal/UniversalModal';
import { PopUpModal } from '../pop-up-modal/PopUpModal';
import check from '../../images/membership-check.svg';
import { packageTypes } from '../../consts';

const SplitForm = ({
  packageId,
  paymentMethod,
  nameBrowser,
  couponCode,
  setSelectedPlan,
  email,
  isRegistered,
  isValidEmail,
}: {
  packageId: number;
  paymentMethod: string;
  nameBrowser: string;
  couponCode: string;
  setSelectedPlan: any;
  email: string;
  isRegistered: boolean;
  isValidEmail: RegExp;
}) => {
  const { previousRoute } = useSelector((state: any) => state.previousRouteState);
  const { idSharedList } = useSelector((state: any) => state.myListsState);
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const stripe = useStripe();
  const elements = useElements();
  const { user } = useSelector((state: any) => state.userState);
  const { coupon } = useSelector((state: any) => state.couponState);
  const usersPaymentMethod = useSelector((state: any) => state.subscriptionState.subscription);

  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTaSConfirmed, setIsTaSConfirmed] = useState<boolean>(false);
  const [useOldCard, setUseOldCard] = useState(!!usersPaymentMethod?.card && isAuthenticated);
  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [isNumFilled, setIsNumFilled] = useState<boolean>(false);
  const [isExFilled, setIsExFilled] = useState<boolean>(false);
  const [isCvcFilled, setIsCvcFilled] = useState<boolean>(false);
  const [isAnnualFromAnnual, setIsAnnualFromAnnual] = useState<boolean>(false);
  const [isFromMonthlyToAnnual, setIsFromMonthlyToAnnual] = useState<boolean>(false);
  const [isAnnualSuccess, setIsAnnualSuccess] = useState<boolean>(false);
  const [price, setPrice] = useState(packageTypes.find((p) => p.id === +packageId)?.stripeAmount);
  const [modalText, setModalText] = useState<string | null>(null);
  const [createdAtSubscription, setCreatedAtSubscription] = useState<any>();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Modal, open, close] = useModal('root', {
    preventScroll: false,
    closeOnOverlayClick: false,
  });

  const optionsPlaceholder = {
    placeholder: 'Card Number',
  };

  useEffect(() => {
    const planPrice = packageTypes.find((p) => p.id === +packageId)?.price as number;
    let amount;

    if (coupon?.valid && coupon?.discount) {
      amount = coupon.discount === 'percent' ? planPrice * (1 - coupon.amount / 100) : planPrice - coupon.amount;
    } else {
      amount = planPrice;
    }

    setPrice(amount < 0 ? 0 : Number(amount.toFixed(2)) * 100);
  }, [coupon, packageId]);

  useEffect(() => {
    if (user?.subscription || user?.hadSubscription) {
      dispatch(subscriptionAction.getPaymentMethodByUserId(user.id));
    }
  }, []);

  const handleNumber = (event) => {
    if (!event.empty) {
      setIsNumFilled(true);
    } else {
      setIsNumFilled(false);
    }
  };

  const handleEx = (event) => {
    if (!event.empty) {
      setIsExFilled(true);
    } else {
      setIsExFilled(false);
    }
  };

  const handleCvc = (event) => {
    if (!event.empty) {
      setIsCvcFilled(true);
    } else {
      setIsCvcFilled(false);
    }
  };

  useEffect(() => {
    if (useOldCard && usersPaymentMethod?.card?.billing_name) {
      const billing_name = usersPaymentMethod?.card?.billing_name?.split(' ');
      setFirstname(useOldCard && billing_name[0] ? billing_name[0] : '');
      setLastname(useOldCard && billing_name[1] ? billing_name[1] : '');
    } else {
      setFirstname('');
      setLastname('');
    }
  }, [useOldCard]);

  useEffect(() => {
    setUseOldCard(!!usersPaymentMethod?.card && isAuthenticated);
  }, [usersPaymentMethod]);

  const checkOrAvailableSubmitForm = (): boolean => {
    return (
      (user?.subscription && user.subscription.packageId === packageId) ||
      (user?.subscription && user.subscription.packageId % 2 === 1 && packageId % 2 === 0)
    );
  };

  const onSubscriptionGranted = () => {
    setModalText('The subscription was successfully granted!');
    open();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (user?.subscription && user.subscription.packageId % 2 === 1) {
      setIsAnnualFromAnnual(true);
      open();
      return;
    }

    const availableSubmit = checkOrAvailableSubmitForm();
    if (availableSubmit) {
      return;
    }

    setIsError(false);
    setIsLoading(true);

    if (!stripe || !elements || !isTaSConfirmed || (!isAuthenticated && !email)) {
      setIsLoading(false);
      if (!isTaSConfirmed) {
        const lable = document.getElementsByClassName('privacy-checkbox')[0] as HTMLElement;
        const input = document.getElementById('card-privacy-input') as HTMLElement;
        lable.style.color = 'red';
        lable.style.border = '1px solid red';
        lable.style.boxShadow = '0 4px 8px rgba(255, 0, 0, 0.4)';
        lable.style.borderRadius = '5px';
        lable.style.padding = '1rem'; 

      }
      if (!isAuthenticated && !email.match(isValidEmail)) {
        const input = document.getElementById('account-email') as HTMLElement;
        input.style.borderColor = 'red';
      }
      return;
    }

    if ((!isNumFilled || !isExFilled || !isCvcFilled) && !useOldCard) {
      setIsLoading(false);
      return;
    }

    if (user?.subscription && user.subscription.packageId % 2 === 0 && packageId % 2 === 1) {
      setIsFromMonthlyToAnnual(true);
      open();
      setIsLoading(false);
      return;
    }

    let data;

    const fullname = `${firstname} ${lastname}`;

    if (user?.subscription) {
      setIsLoading(true);
      let payload;

      if (useOldCard) {
        payload = usersPaymentMethod;
      } else {
        payload = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
          billing_details: {
            name: user?.name,
            email: user.email,
          },
        });
      }
      data = await subscriptionService
        .endpoint_update_subscription(user.subscription.id, {
          email: user.email,
          paymentMethodId: payload.paymentMethod?.id || payload.id,
          packageId,
          couponCode,
          timeSubscription: packageId % 2 === 0 ? 'month' : 'year',
          fullname,
        })
        .catch(() => {
          setIsError(true);
          setIsLoading(false);
          open();
        });
      if (data) {
        setSelectedPlan(0);
        dispatch(userAction.getUser({ email: user.email }));
        setIsLoading(false);
        dispatch(
          loadingAction.updateLoadingStatus({
            getUserLoading: false,
          }),
        );
      }
    } else {
      let payload;
      if (!useOldCard) {
        payload = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
          billing_details: {
            name: user?.name,
            email: isAuthenticated ? user.email : email,
          },
        });
      }

      data = await subscriptionService
        .endpoint_create_subscription({
          email: isAuthenticated ? user.email : email,
          paymentMethodId: payload?.paymentMethod?.id || usersPaymentMethod?.id,
          packageId,
          couponCode,
          timeSubscription: packageId % 2 === 0 ? 'month' : 'year',
          fullname,
          type: 'card',
        })
        .catch(() => {
          setIsError(true);
          setIsLoading(false);
          open();
        });

      setSelectedPlan(0);

      if (data) {
        setCreatedAtSubscription(data.data.createdAt);
        setIsLoading(false);
        if (previousRoute.payload === '/shared-list-after-require-pay' && isAuthenticated) {
          updateUserWithSub();
          if (!idSharedList) return;
          navigate(`/shared-lists/${idSharedList}`);
          // dispatch(previousRouteAction.setPreviousRoute('/shared-list-after-require-pay-with-payed'));
          return;
        }
        onSubscriptionGranted();
      }
    }
  };

  const updateUserWithSub = () => {
    /*const body = {
      email: user.email,
      hadSubscription: true,
      canceledSubscription: false,
      countDownloadCsv: 1,
      createdAtSubscription: new Date(),
      statusSubscription: 'active',
      typeSubscription: packageId % 2 === 0 ? 'monthly' : 'annual',
    };
     dispatch(userAction.updateUser({ user: body }));
     */
    dispatch(userAction.getUser({ email: user.email }));
    dispatch(
      loadingAction.updateLoadingStatus({
        getUserLoading: false,
      }),
    );
  };

  const getNumberOfDaysUntilSubscriptionExpires = (expiredDateSub: Date): number => {
    const expiredAt: Date = new Date(expiredDateSub);
    const today: Date = new Date();
    const diffInMilliseconds: number = expiredAt.getTime() - today.getTime();
    return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  };

  return (
    <form className="stripe-form-wrapper" onSubmit={handleSubmit}>
      <div className="stripe-form">
        {paymentMethod === 'card' && (
          <>
            {isAuthenticated && usersPaymentMethod?.card && paymentMethod === 'card' && (
              <div className="card-radios">
                <div>
                  <input
                    type="radio"
                    name="card"
                    value="yes"
                    checked={useOldCard}
                    onChange={() => setUseOldCard(true)}
                  />
                  <p className="old-credit">Use Credit Card on File... {usersPaymentMethod?.card.last4}</p>
                  <p className={useOldCard ? 'old-credit-mobile violet' : 'old-credit-mobile'}>
                    Use Credit Card... {usersPaymentMethod?.card.last4}
                  </p>
                </div>
                <div>
                  <input
                    type="radio"
                    name="card"
                    value="no"
                    checked={!useOldCard}
                    onChange={() => setUseOldCard(false)}
                  />
                  <p className={!useOldCard ? 'violet' : ''}>Add New Card</p>
                </div>
              </div>
            )}
            <label htmlFor="card-number-input" className="card-number">
              {!useOldCard ? (
                <CardNumberElement
                  onBlur={handleNumber}
                  id="card-number-input"
                  className="card-number-input"
                  options={optionsPlaceholder}
                />
              ) : (
                <input
                  value={`●●●● ●●●● ●●●● ${usersPaymentMethod?.card.last4}`}
                  id="card-custom-number"
                  className="card-holder"
                  placeholder="Card number"
                  disabled
                />
              )}
            </label>
            <div className="card-date-cvc">
              <label htmlFor="card-date-input">
                {!useOldCard ? (
                  <CardExpiryElement onBlur={handleEx} id="card-date-input" className="card-date-input" />
                ) : (
                  <input
                    value={`${String(usersPaymentMethod?.card?.exp_month).padStart(2, '0')} / ${String(
                      usersPaymentMethod?.card?.exp_year,
                    ).slice(-2)}`}
                    id="card-custom-expiry"
                    className="card-holder"
                    placeholder="MM / YY"
                    disabled
                  />
                )}
              </label>
              <label htmlFor="card-cvc-input">
                {!useOldCard ? (
                  <CardCvcElement onBlur={handleCvc} id="card-cvc-input" className="card-cvc-input" />
                ) : (
                  <input value="●●●" id="card-custom-cvc" className="card-holder" placeholder="CVC" disabled />
                )}
              </label>
            </div>
            <div className="card-user-name">
              <label htmlFor="card-holder-firstname">
                <input
                  value={firstname ?? ''}
                  id="card-holder-firstname"
                  className="card-holder"
                  placeholder="First Name"
                  aria-label=""
                  onChange={({ target }) => setFirstname(target.value)}
                  disabled={useOldCard}
                />
              </label>
              <label htmlFor="card-holder-lastname">
                <input
                  value={lastname ?? ''}
                  id="card-holder-lastname"
                  className="card-holder"
                  placeholder="Last Name"
                  aria-label=""
                  onChange={({ target }) => setLastname(target.value)}
                  disabled={useOldCard}
                />
              </label>
            </div>
          </>
        )}

        {paymentMethod === 'apple-pay' && nameBrowser === 'Apple Safari' && (
          <ApplePay
            onSubscriptionGranted={onSubscriptionGranted}
            packageId={packageId}
            price={price as number}
            couponCode={couponCode}
            email={email}
            isValidEmail={isValidEmail}
          />
        )}

        {paymentMethod !== 'apple-pay' && (
          <label htmlFor="card-privacy-input" className="card-number privacy-checkbox">
            <h5 className="stripe-form-title">
              Accept Terms of Service
            </h5>
            <div className="privacy-checkbox-cont">
              <input
                type="checkbox"
                id="card-privacy-input"
                checked={isTaSConfirmed}
                onChange={() => {
                  if (!isTaSConfirmed) {
                    const lable = document.getElementsByClassName('privacy-checkbox')[0] as HTMLElement;
                    const input = document.getElementById('card-privacy-input') as HTMLElement;
                    lable.style.color = '#030054';
                    input.style.borderColor = '#c2c2c2';
                  }
                  setIsTaSConfirmed(!isTaSConfirmed);
                }}
              />
              {paymentMethod !== 'apple-pay' && (
                <p>
                  I have read and accept the Road2College{' '}
                  <a href="https://www.road2college.com/terms-and-conditions/" target="_blank" rel="noreferrer">
                    Terms of Service
                  </a>
                  . I understand I can cancel my subscription at any time but Road2College does not issue refunds or
                  prorated amounts on previous charges.
                </p>
              )}
            </div>
          </label>
        )}
      </div>
      {isError && <div className="error">Invalid data</div>}
      {isLoading ? (
        <SyncLoader
          className="payment-loading"
          color="var(--color-primary)"
          margin={7}
          size={20}
          speedMultiplier={0.7}
        />
      ) : (
        paymentMethod !== 'apple-pay' && (
          <button className={'primary-button'} type="submit" id="submit-payment" disabled={!stripe && isError}>
            Upgrade NOW
          </button>
        )
      )}
      <Modal>
        {isAnnualFromAnnual && (
          <UniversalModal
            close={() => {
              close();
              setIsAnnualFromAnnual(false);
            }}
            textForTitle={'Existing Paid Subscriber!'}
            textForDescription={`Hi! You’re already an annual subscriber and have ${getNumberOfDaysUntilSubscriptionExpires(
              user.subscription.expiresAt,
            )} days left on your current subscription. We only allow one annual subscription at a time per account.\n`}
            textSecondBtn={'Ok'}
          />
        )}
        {isError && (
          <UniversalModal
            close={() => {
              close();
              setIsError(false);
            }}
            textForTitle={'Update payment method!'}
            textForDescription={
              'Unfortunately the transaction didn’t go through. Please check your credit details again and re-submit your payment.'
            }
            textSecondBtn={'Ok'}
          />
        )}
        {isFromMonthlyToAnnual && (
          <UniversalModal
            close={() => {
              close();
              setIsFromMonthlyToAnnual(false);
            }}
            open={open}
            textForTitle={'Upgrade to Annual'}
            textForDescription={
              'You currently have a monthly subscription. Upgrading to annual immediately gets you all the same benefits and more. Select Upgrade now and select your payment method.'
            }
            textFirstBtn={'Cancel'}
            textSecondBtn={'Upgrade'}
            couponCodeNew={couponCode}
            firstname={firstname}
            lastname={lastname}
            useOldCard={useOldCard}
            packageId={packageId}
            setIsLoading={setIsLoading}
            setIsError={setIsError}
            setIsAnnualSuccess={setIsAnnualSuccess}
          />
        )}
        {isAnnualSuccess && (
          <UniversalModal
            close={() => {
              close();
              setIsAnnualSuccess(false);
            }}
            textForTitle={'Success!'}
            textForDescription={'You’ve upgraded! Your new Annual expires 1 year from today.'}
            textSecondBtn={'Ok'}
            setIsLoading={setIsLoading}
            setIsError={setIsError}
            packageId={packageId}
            isAnnualSuccess={isAnnualSuccess}
          />
        )}
        {modalText && (
          <PopUpModal
            close={() => {
              if (isAuthenticated) {
                updateUserWithSub();
                navigate('/home-page');
              }
              close();
              setModalText(null);
              if (!isAuthenticated) {
                loginWithRedirect(
                  isRegistered
                    ? { screen_hint: 'login', login_hint: email }
                    : { screen_hint: 'signup', login_hint: email },
                );
              }
            }}
            textForTitle={modalText}
          />
        )}
      </Modal>
    </form>
  );
};

export default SplitForm;
